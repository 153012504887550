import axios from "axios";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
let allDatesByRoom = null;

function Reservation({ session, setSession, userPicture }) {
  const CLIENT_ID =
    "128491853917-2p3363ssfmqvroc01siq5v0irptvg5u.apps.googleusercontent.com";
  const SCOPES = "https://www.googleapis.com/auth/calendar";
  const cookies = new Cookies();
  const localizer = momentLocalizer(moment);
  const [sala, setSala] = useState("Sala VIP");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [bgModalHeader, setBgModalHeader] = useState("");
  const modalReservacionRef = useRef(null);
  const modalReservacionInfoRef = useRef(null);
  const modalCalendarioRef = useRef(null);
  const [modalReservationView, setModalReservationView] = useState(false);
  const [modalReservationInfoView, setModalReservationInfoView] =
    useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState("");
  const [guests, setGuests] = useState("");
  const [addedGuests, setAddedGuests] = useState([{ email: cookies.get("email") }]);
  const [events, setEvents] = useState([]);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [owner, setOwner] = useState("");
  const [reservationStatus, setReservationStatus] = useState(false);
  const [deleteReservationStatus, setDeleteReservationStatus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isStaff, setIsStaff] = useState(false);


/* The above code is using the `useEffect` hook in React to set a timeout function that will hide a
modal after 60 seconds if the `modalIsOpen` state is true. It checks if the `modalIsOpen` state is
true, and if it is, it sets a timeout function that will call `modalAction("hide",
modalCalendarioRef.current)` to hide the modal and then sets the `modalIsOpen` state to false after
60 seconds. The `useEffect` hook will run whenever the `modalIsOpen` state changes. */
  useEffect(() => {
    if(modalIsOpen){
      setTimeout(() => {
        closeModal();
        modalAction("hide", modalCalendarioRef.current);
      }, 60000);
    }
  },[modalIsOpen])

  const closeModal = ()  => {
    setModalIsOpen(false);
  }

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    allDatesByRoom = events;
  }, [events]);

  // ********************************* Deteccion de Sala actual *********************************
  /* The above code is using the `useEffect` hook in a React component to load all reservations for a
given `sala` (room) and log the current `sala` to the console. The `useEffect` hook is triggered
whenever the `sala` variable changes. The `loadAllReservations` function is likely an asynchronous
function that fetches reservation data from an API or database. The `eslint-disable-next-line`
comment is used to disable a specific linting rule for this line of code. */
  useEffect(() => {
    console.info("Sala actual: ", sala);
    loadAllReservations(sala);
    console.info("Sala actual: ", sala);
    if (sala === "Sala VIP") {
      setBgModalHeader("bg-salaVip");
    } else if (sala === "Editorial") {
      setBgModalHeader("bg-salaEditorial");
    } else if (sala === "Estudio de Podcast") {
      setBgModalHeader("bg-salaPodcast");
    } else if (sala === "Estudio") {
      setBgModalHeader("bg-salaVideo");
    } else if (sala === "Presidencia") {
      setBgModalHeader("bg-salaPresidencia");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sala]);
  // ********************************* Seteo de eventos en el calendario *********************************
  /**
   * The function adds a new event to an array of events and updates the events.
   * @param data - The `data` parameter is a variable that contains information about a calendar event
   * that needs to be added to an array of events.
   */
  function setCalendarEvents(data) {
    var arr = events;
    arr.push(data);
    setEvents(arr);
  }

  function deleteCalendarEvent() {
    const idx = events.indexOf(deleteEvent);
    events.splice(idx, 1);
    setEvents(events);
  }
  // ********************************* Deteccion del status de la reservacion realizada  *********************************
  /* The above code is a React useEffect hook that listens for changes in the `reservationStatus` state
variable. If the `reservationStatus` is true, it logs a message to the console, hides a modal,
clears a form, shows a success toast message, removes some items from the local storage, and sets
the `reservationStatus` to false. This code is likely part of a larger React component that handles
reservations for an event or activity. */
  useEffect(() => {
    if (reservationStatus === true) {
      console.info("Estado de reservacion success");
      modalAction("hide", modalReservacionRef.current);
      setTimeout(() => {
        cleanForm();
      }, 1000);
      showToast("success", "Reservación realizada");
      toast.clearWaitingQueue();
      window.localStorage.removeItem("evento");
      window.localStorage.removeItem("data");
      setSala(sala);
      setReservationStatus(false);
    }
  }, [reservationStatus]);
  // ********************************* Deteccion del status de la reservacion eliminada  *********************************
  /* The below code is a React useEffect hook that listens for changes in the `deleteReservationStatus` state
variable. If the `deleteReservationStatus` is true, it logs a message to the console, hides a modal,
clears a form, shows a success toast message, removes some items from the local storage, and sets
the `deleteReservationStatus` to false. This code is likely part of a larger React component that handles delete of
reservations for an event or activity. */
  useEffect(() => {
    if (deleteReservationStatus === true) {
      console.info("Estado de eliminacion de reservacion success");
      modalAction("hide", modalReservacionInfoRef.current);
      setTimeout(() => {
        cleanForm();
      }, 1000);
      showToast("success", "Reservación eliminada");
      toast.clearWaitingQueue();
      window.localStorage.removeItem("evento");
      window.localStorage.removeItem("data");
      console.log("Reiniciando sala");
      setSala(sala);
      setDeleteReservationStatus(false);
    }
  }, [deleteReservationStatus]);
  // ********************************* Configuracion de fecha por default en el calendario *********************************
  /* The above code is using the `useMemo` hook in React to create a memoized object with a `defaultDate`
property. The `defaultDate` property is set to a new `Date` object with the current year, month, and
day. The `useMemo` hook is used to optimize performance by only recomputing the object when its
dependencies change. In this case, there are no dependencies, so the object is only computed once. */
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
    }),
    []
  );
  // *********************************  Inicio ,  generar token de acceso y autentificacion para google calendar // *********************************
  /* The above code is using the Google OAuth2 API to obtain an access token for the user. Once the
access token is obtained, it is stored in the user's local storage. Then, a POST request is made to
the Google Calendar API to create a new event in the user's primary calendar. The request includes
the access token in the Authorization header and the event details in the request body. If the
request is successful, the function `saveReservationDb()` is called to save the reservation in a
database. */
  const clients = window.google.accounts.oauth2.initTokenClient({
    client_id:
      "128491853917-p2p3363ssfmqvroc01siq5v0irptvg5u.apps.googleusercontent.com",
    scope: SCOPES,
    callback: (tokenResponse) => {
      console.info("Token de acceso a Google Client");
      console.info(tokenResponse);
      window.localStorage.setItem("token", tokenResponse.access_token);
      if (tokenResponse && tokenResponse.access_token) {
        // se ejecuta cuando se llama client.requestAccessToken(); y se genera el token
        console.info("Ejecutando la petición de Access Token");
        fetch(
          "https://www.googleapis.com/calendar/v3/calendars/primary/events?sendUpdates=all",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            body: window.localStorage.getItem("evento"),
          }
        )
          .then((response) => response.json())
          .then((json) => saveReservationDb())
          .catch((err) => console.log(err));
      }
    },
    prompt: "",
  });
  // *********************************  Se cargan todas las reservaciones de la BD // *********************************
  /**
 * The function loads reservations from a database and filters them based on the selected room.
 * Returns an array of objects with status success
 * {
    "status": "success",
    "reservaciones": [
        {
            "_id": "648393118c85658a7d9527d1",
            "asunto": "prueba 10 a 11",
            "fechaHoraInicio": "2023-06-09T10:00:00.000",
            "fechaHoraFin": "2023-06-09T11:00:00.000",
            "invitados": "daniel.montalvo@mmkgroup.com.mx",
            "sala": "Sala VIP",
            "__v": 0
        }
 */

  function addGuest(event) {
    if(event.key === " " || event.target.dataset.action === "Button"){
      if (guests.includes("@")) {
        const existeEmail = addedGuests.some((informacion) => {
          return informacion.email.trim() === guests.trim();
        })
        if(!existeEmail){
          setAddedGuests((prevEmail) => [...prevEmail, { email: guests}]);
          setGuests("");
        }
      } else {
        return false;
      }
    }
  }


  function deleteEmail(e){
    const updateAddedGuests = addedGuests.filter((guest) => {
      return guest.email !== e.target.dataset.email;
    })
    setAddedGuests(updateAddedGuests);
  }

  function loadUsers() {
    var ahora = new Date();
    var milisegundos = ahora.getMilliseconds();
    axios({
      url: process.env.REACT_APP_HOST + `/api/usuarios/?v=${milisegundos}`,
      method: "GET",
    })
      .then(function (response) {
        if (response.data.status) {
          console.log("info usuarios: ", response.data);
          getCurrentUser(response.data.body.users.Items);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function getCurrentUser(data) {
    for (let i = 0; i < data.length; i++) {
      if (cookies.get("email") === data[i].email.S) {
        if (data[i].role.S === "admin") {
          setIsAdmin(true);
        }
        if (data[i].role.S === "director") {
          setIsDirector(true);
        }
        if (data[i].role.S === "video") {
          setIsVideo(true);
        }
        if (data[i].role.S === "staff") {
          setIsStaff(true);
        }
      }
    }
  }
  function loadAllReservations() {
    var ahora = new Date();
    var milisegundos = ahora.getMilliseconds();
    axios({
      url: process.env.REACT_APP_HOST + `/api/reservaciones/?v=${milisegundos}`,
      method: "GET",
    })
      .then(function (response) {
        console.info("Reservas obtenidas");
        console.info(response);
        console.info(response.data.body.reservaciones.Items[0]);
        if (response.data.body.reservaciones.Items.length > 0) {
          console.info(
            "Carga de reservaciones de la BD: ",
            response.data.body.reservaciones.Items
          );
          let reservacionesList = response.data.body.reservaciones.Items;
          if (response.data.status === "success" && sala === "Sala VIP") {
            filterReservations(reservacionesList, sala);
          }
          if (response.data.status === "success" && sala === "Editorial") {
            filterReservations(reservacionesList, sala);
          }
          if (
            response.data.status === "success" &&
            sala === "Estudio de Podcast"
          ) {
            filterReservations(reservacionesList, sala);
          }
          if (response.data.status === "success" && sala === "Hall") {
            filterReservations(reservacionesList, sala);
          }
          if (response.data.status === "success" && sala === "Estudio") {
            filterReservations(reservacionesList, sala);
          }
          if (response.data.status === "success" && sala === "Presidencia") {
            filterReservations(reservacionesList, sala);
          }
        } else {
        }
      })
      .catch(function (error) {
        console.log("Error al obtener las reservaciones");
        console.log(error);
      });
  }
  // *********************************  Se filtran las reservaciones de la BD // *********************************
  /**
   * The function filters reservations by room and date, transforms the date format, and sets the events
   * for a calendar.
   * @param response - The response object containing the data to be filtered and transformed into events
   * for the calendar.
   * @param sala - `sala` is a variable representing the room or space being filtered for reservations.
   * The function `filterReservations` takes in a response object and a `sala` parameter, and filters the
   * reservations in the response object to only include those for the specified room.
   */
  function filterReservations(response, sala) {
    let dataByRoom = null;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0);

    var data = response; //response.data.reservaciones;
    console.log("Datos a filtrar");
    console.log(data);
    /*dataByRoom = data.filter(
      (value) =>
        value.sala.S === sala &&
        new Date(value.fechaHoraInicio.S) >= currentDate
	);*/
    dataByRoom = data.filter((value) => value.sala.S === sala);

    console.info("Datos filtrados por sala: ", dataByRoom);
    //Se transforma la fecha inicial y final  para el formato del calendario y poder setear
    setEvents([]);
    var arr = [];
    for (let i = 0; i < dataByRoom.length; i++) {
      arr.push({
        start: new Date(dataByRoom[i].fechaHoraInicio.S),
        end: new Date(dataByRoom[i].fechaHoraFin.S),
        title: dataByRoom[i].asunto.S,
        sala: dataByRoom[i].sala.S,
        id: dataByRoom[i].reservacionId.S,
      });
    }
    setEvents(arr);
  }
  // ********************************* Evento en el click y drag del slot del calendario // *********************************
  /* The above code is defining a function called `handleSelectSlot` using the `useCallback` hook. This
function takes an object with `start`, `end`, and `slots` properties as its argument. It first
checks if the selected date is in the past, and if so, it logs a message and returns null. If the
selected date is not in the past, it calls a function called `checkIfDateIsAvailable` with the
`start`, `end`, and `events` arguments. If this function returns true, it logs a message and returns
null. If the function returns */
  const handleSelectSlot = useCallback(({ start, end, slots }) => {
    //Se determina si la fecha seleccionada es de dias pasados
    if (moment(start).isBefore(moment(), "day") === true) {
      console.info("No se puede seleccionar una fecha atrasada");
      return null;
    } else {
      let resp = checkIfDateIsAvailable(start, end, allDatesByRoom);
      if (resp === true) {
        console.info(
          "No se puede seleccionar este rango de fecha. Abriendo modal de información de evento"
        );
        return null;
      } else {
        console.log("Fecha valida para asignar");
        setModalReservationView(true);
        modalAction("show", modalReservacionRef.current);
        detectCloseModal(modalReservacionRef.current);
        setStartDate(moment(start).format("YYYY-MM-DDTHH:mm:ss.SSS"));
        setEndDate(moment(end).format("YYYY-MM-DDTHH:mm:ss.SSS"));
        setOwner(cookies.get("email"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ********************************* Informacion del evento // *********************************
  /* The above code is defining a function called `handleEvent` using the `useCallback` hook. This
function will display the information of the clicked event. If the owner is the one visualizing the information it can be possible to delete de event */
  const handleEvent = useCallback((event) => {
    //Se obtienen los datos del evento y se muestran en un modal
    console.log(event);
    setModalReservationInfoView(true);
    modalAction("show", modalReservacionInfoRef.current);
    detectCloseModal(modalReservacionInfoRef.current);
    setStartDate(moment(event.start).format("YYYY-MM-DDTHH:mm:ss.SSS"));
    setEndDate(moment(event.end).format("YYYY-MM-DDTHH:mm:ss.SSS"));
    setOwner(event.id.split("-")[0]);
    setSubject(event.title);
    setDeleteEvent(event);
    //alert(JSON.stringify(event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ********************************* Funcion que verifica si la fecha esta disponible para seleccionar // *********************************
  /**
   * The function checks if a given date range is available by comparing it with existing reservations.
   * @param startDate - The start date of the new reservation to check availability for.
   * @param endDate - The endDate parameter is a date representing the end date of a reservation period.
   * @param reservations - an array of objects representing existing reservations. Each object has two
   * properties: "start" and "end", which are strings representing the start and end dates of the
   * reservation in the format "YYYY-MM-DD".
   * @returns a boolean value indicating whether the given date range (startDate to endDate) is available
   * or not based on the reservations array provided. If the reservations array is empty, the function
   * returns undefined.
   */
  function checkIfDateIsAvailable(startDate, endDate, reservations) {
    console.info("reservaciones a checar: ", reservations);
    var resp = false;
    if (reservations.length !== 0) {
      for (let i = 0; i < reservations.length; i++) {
        if (new Date(startDate) >= new Date(reservations[i].start)) {
          if (new Date(startDate) < new Date(reservations[i].end)) {
            resp = true;
            break;
          }
        } else {
          if (new Date(endDate) > new Date(reservations[i].start)) {
            resp = true;
            break;
          }
        }
      }
      return resp;
    }
  }

  // ********************************* Funcion para abrir y cerrar los modales // *********************************
  /**
   * This is a JavaScript function that shows or hides a Bootstrap modal based on the action parameter.
   * @param action - The action parameter is a string that specifies whether to show or hide a modal. If
   * the value of action is "show", the function will show the modal with the specified id. If the value
   * of action is anything else, the function will hide the modal with the specified id.
   * @param idModal - idModal is a string parameter that represents the ID of the modal element that
   * needs to be shown or hidden. It is used to identify the specific modal element that needs to be
   * opened or closed.
   */
  const modalAction = (action, idModal) => {
    if (action === "show") {
      console.info("mostrando modal: ", idModal);
      let modalVariantOpen = new bootstrap.Modal(idModal);
      modalVariantOpen.show();
    } else {
      console.info("Cerrando modal: ", idModal);
      var modalVariantClose = bootstrap.Modal.getInstance(idModal);
      modalVariantClose.hide();
      let backdropModal = document.getElementsByClassName("modal-backdrop");
      backdropModal[0].remove();
    }
  };
  // ********************************* Funcion para limpiar formulario // *********************************
  /**
   * The function cleans the data of a reservation form.
   */
  function cleanForm() {
    console.info("Limpiando Datos de reservación");
    setSubject("");
    setStartDate(null);
    setEndDate(null);
    setGuests("");
  }
  // ********************************* Funcion para detectar el cierre del modal // *********************************
  /**
   * This function detects the closing of a modal and sets a state variable to false.
   * @param idModal - The idModal parameter is a reference to the modal element that the function is
   * listening for the "hidden.bs.modal" event on. This event is triggered when the modal is closed or
   * hidden from view.
   */
  const detectCloseModal = (idModal) => {
    // Evento para detectar el cierre del modal
    idModal.addEventListener("hidden.bs.modal", function (event) {
      console.info("cerrando modal: ", idModal);
      setModalReservationView(false);
    });
  };
  // ********************************* Funcion para hacer la reservacion // *********************************
  /**
   * The function creates a reservation object with start and end dates, a title, and a room, adds it to
   * a calendar, and logs the reservation information.
   */
  const reserve = () => {
    var reservacion = {
      id: cookies.get("email") + "-" + new Date(startDate),
      start: new Date(startDate),
      end: new Date(endDate),
      title: subject,
      sala: sala,
    };
    setCalendarEvents(reservacion);
    console.log("Asignando evento a react calendar", reservacion);
    setEventInGoogle();
  };
  // ********************************* Funcion que detecta el cambio de los inputs  // *********************************
  /**
   * The function watches for changes in the input fields for subject and guests and updates their values
   * accordingly.
   * @param e - The parameter "e" is an event object that is passed to the function "watchInput" when an
   * event (such as a user typing in an input field) occurs. It contains information about the event,
   * such as the target element that triggered the event and the value of the input field.
   */
  function watchInput(e) {
    if (e.target.id === "subjectInput") {
      setSubject(e.target.value);
    }
    if (e.target.id === "guestInput") {
      setGuests(e.target.value);
    }
  }
  // ********************************* Funcion para mostrar las variantes de toastify // *********************************
  /**
   * The function showToast displays a toast message with a specified type and message.
   * @param typeToast - A string that specifies the type of toast to display. It can be "success",
   * "danger", "warning", or any other string for an info toast.
   * @param message - The message to be displayed in the toast notification.
   */
  function showToast(typeToast, message) {
    let varToast;
    switch (typeToast) {
      case "success":
        varToast = toast.success;
        break;
      case "danger":
        varToast = toast.danger;
        break;
      case "warning":
        varToast = toast.warning;
        break;
      default:
        varToast = toast.info;
    }
    varToast(message, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  // ********************************* Funcion que asigna la sala seleccionada // *********************************
  /**
   * The function loads a room and displays a calendar modal after a short delay.
   * @param room - The `room` parameter is a variable that represents the room that needs to be loaded.
   * It is used in the `loadRoom` function to check if the current room is different from the one that
   * needs to be loaded. If they are different, the function sets the new room and then shows a
   */
  function loadRoom(room) {
    console.info("Asignando sala: ", room);
    setSala(room);
    setTimeout(() => {
      modalAction("show", modalCalendarioRef.current);
      setModalIsOpen(true);
    }, 350);
  }
  // ********************************* Funcion que asigna el evento a google calendar // *********************************
  /**
   * The function sets up a Google calendar event with details such as the event name, location, start
   * and end time, attendees, and reminders.
   */
  function setEventInGoogle() {
    clients.requestAccessToken();

    // Se actualiza organizador para que se le envie el correo de notificacion de la creacion del evento
    const invitados = addedGuests.map(user => {
      if(user.email ===  cookies.get("email")){
        let splitEmail = user.email.split("@");
        return { ...user, email: splitEmail[0] + '+1@' +splitEmail[1] }
      }else{
        return user;
      }
    });

    let evento = {
      summary: "Reservación " + sala + " ," + subject,
      location:
        "Alejandro Dumas 243, Polanco, Polanco IV Secc, Miguel Hidalgo, 11550 Ciudad de México, CDMX",
      description: subject,
      start: {
        dateTime: startDate,
        timeZone: "America/Mexico_City",
      },
      end: {
        dateTime: endDate,
        timeZone: "America/Mexico_City",
      },
      recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
      attendees: invitados,
      reminders: {
        useDefault: true,
      },
      sendUpdates: "all",
    };
    console.info("Datos a almacenar en Local");
    console.info(evento);
    window.localStorage.setItem("evento", JSON.stringify(evento));
    console.log("se almacena en localStorage el evento", evento);
    let array_guest = guests.split(",");
    let verified_guests = [];
    var emailRegex =
      /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;
    array_guest.forEach((element) => {
      if (element.match(emailRegex)) verified_guests.push(element);
    });

    let data = {
      id: cookies.get("email") + "-" + startDate,
      asunto: subject,
      fechaHoraInicio: startDate,
      fechaHoraFin: endDate,
      invitados:
        verified_guests.length <= 0
          ? cookies.get("email")
          : verified_guests.toString(),
      sala: sala,
    };
    console.log("data del google calendar", data);
    window.localStorage.setItem("data", JSON.stringify(data));
  }
  // ********************************* Funcion para guardar la reservacion en la bd // *********************************
  /**
   * The function saves reservation data to a database using an HTTP POST request.
   */
  function saveReservationDb() {
    let data = JSON.parse(window.localStorage.getItem("data"));
    data["reservacionId"] =
      cookies.get("email") + "-" + data["fechaHoraInicio"];
    // Se guarda en bd datos de reservacion
    console.info("Se busca guardar en la BD el evento");
    axios
      .request({
        url: process.env.REACT_APP_HOST + "/api/save",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      })
      .then(function (response) {
        console.info("Respuesta de guardado en la BD");
        console.info(response);
        if (response.data.status === "success") {
          setReservationStatus(true);
          console.log("Reservacion realizada y guarda en la BD: ", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // ********************************* Funcion para eliminar la reservacion en la bd // *********************************
  /**
   * The function deletes reservation from database using an HTTP DELETE request.
   */
  function deleteReservationDb() {
    let reservacionId = cookies.get("email") + "-" + startDate;
    let data = {
      reservacionId: reservacionId,
    };
    // Se guarda en bd datos de reservacion
    console.info("Se busca eliminar de la BD el evento");
    axios
      .request({
        url: process.env.REACT_APP_HOST + "/api/reservaciones",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      })
      .then(function (response) {
        console.info("Respuesta de guardado en la BD");
        console.info(response);
        if (response.data.status === "success") {
          setDeleteReservationStatus(true);
          console.log("Reservacion eliminada de la BD");
          deleteCalendarEvent();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // ********************************* Funcion que muestra los datos completos del evento en el calendario // *********************************
  // const handleSelectEvent = useCallback((event) => {
  //   console.log(event);
  //   window.alert(event.title);
  // }, []);

  return (
    <>
      <ToastContainer limit={1} id="customToast" />
      <div className="profile">
        <div className="sesion">
          {session && (
            <div className="row text-black w-100 m-0 d-flex flex-column align-content-end">
              <div className="col-12 col-sm-6 text-center p-0 mt-3">
                <img
                  className="imgProfile mt-2"
                  src={userPicture}
                  alt="perfil"
                  referrerPolicy="no-referrer"
                />
                <small className="d-block text-white pt-1">Bienvenido:</small>
                <small className="d-block text-white pt-0">
                  {cookies.get("given_name")}
                </small>
              </div>
              <div className="col-12 col-sm-6 text-center p-0 d-flex align-items-center justify-content-center mt-5">
                <button
                  className="btn btn-sm btn-primary py-1 mt-4 px-2 btnCloseSession"
                  onClick={() => {
                    setSession(false);
                  }}
                >
                  Cerrar Sesión
                </button>
              </div>
            </div>
          )}
        </div>
        {/* inicio modal calendario  */}
        <div
          className="modal"
          tabIndex="-1"
          id="calendarModal"
          aria-labelledby="calendarModal"
          aria-hidden="true"
          ref={modalCalendarioRef}
          style={
            modalReservationView
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        >
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className={"modal-header text-white " + bgModalHeader}>
                <h5 className="modal-title">{sala}</h5>
                <button
                  type="button"
                  className="btn-close bg-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Calendar
                  defaultDate={defaultDate}
                  defaultView={"week"}
                  views={["week", "agenda"]}
                  events={events}
                  localizer={localizer}
                  onSelectEvent={handleEvent}
                  onSelectSlot={handleSelectSlot}
                  selectable
                  // start time 8:00am
                  min={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate(),
                      7
                    )
                  }
                  // end time 6:00pm
                  max={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate(),
                      23
                    )
                  }
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* fin modal calendario  */}
        {/* inicio modal reservacion  */}
        <div
          className="modal fade"
          id="modalReservacion"
          ref={modalReservacionRef}
          tabIndex="-1"
          aria-labelledby="modalReservacion"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className={"modal-header text-white " + bgModalHeader}>
                <h5 className="modal-title px-5 text-center">
                  Agrega el asunto de tu reunion y tus invitados
                </h5>
                <button
                  type="button"
                  className="btn-close bg-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-5">
                <label htmlFor="subjectInput">Asunto</label>
                <input
                  id="subjectInput"
                  type="text"
                  className="form-control mb-4"
                  aria-label="asunto"
                  aria-describedby="basic-addon1"
                  onChange={watchInput}
                  value={subject}
                />
                <label htmlFor="startInput">Hora inicio</label>
                <input
                  id="startInput"
                  className="form-control mb-4"
                  type="text"
                  value={moment(startDate).format("MMMM Do YYYY, h:mm:ss a")}
                  readOnly
                />
                <label htmlFor="endInput">Hora fin</label>
                <input
                  id="endInput"
                  className="form-control mb-4"
                  type="text"
                  value={moment(endDate).format("MMMM Do YYYY, h:mm:ss a")}
                  readOnly
                ></input>
                <label htmlFor="guestInput">Invitados (opcional)</label>
                <div className="input-group mb-3">
                  <input
                    id="guestInput"
                    type="text"
                    className="form-control"
                    placeholder="Invitados"
                    aria-label="Invitados"
                    aria-describedby="button-addonGuest"
                    onChange={watchInput}
                    value={guests}
                    onKeyDown={addGuest}
                  />
                  <button
                    data-action="Button"
                    onClick={addGuest}
                    className="btn btn-primary"
                    type="button"
                    id="button-addonGuest"
                  >
                    Agregar
                  </button>
                </div>
                {/* Invitados agregados */}
                {addedGuests.length > 0 &&
                  addedGuests.map((guestEmail, index) => {
                    return (
                      guestEmail.email !==  cookies.get("email") &&
                      <span
                        key={index}
                        className="badge bg-body-tertiary text-dark  px-4 py-2 shadow-sm m-2 position-relative text-muted"
                      >
                        {guestEmail.email}
                        <button
                          data-email={guestEmail.email}
                          onClick={deleteEmail}
                          type="button"
                          className="btn-close  position-absolute top-0 border border-light"
                          style={{ right: "-7px" }}
                          aria-label="Close"
                        ></button>
                      </span>
                    );
                  })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={reserve}
                  disabled={
                    startDate !== null && endDate !== null && subject !== ""
                      ? false
                      : true
                  }
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* fin modal reservacion  */}
        {/* inicio modal informacion de reservacion  */}
        <div
          className="modal fade"
          id="modalReservacion"
          ref={modalReservacionInfoRef}
          tabIndex="-1"
          aria-labelledby="modalReservacionInfo"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className={"modal-header text-white " + bgModalHeader}>
                <h5 className="modal-title px-5 text-center">{subject}</h5>
                <button
                  type="button"
                  className="btn-close bg-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-5">
                <h6>Reservado por: {owner}</h6>
                <p>
                  Hora inicio:{" "}
                  {moment(startDate).format("MMMM Do YYYY, h:mm:ss a")}
                </p>
                <p>
                  Hora fin: {moment(endDate).format("MMMM Do YYYY, h:mm:ss a")}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                {owner === cookies.get("email") && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={deleteReservationDb}
                  >
                    Eliminar evento
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* fin modal informacion de reservacion  */}
        <div className="rooms">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="sidebarRooms">
                <div className="sideBar">
                  <h1 className="text-white textTitle">Salas</h1>

                  <div className="containerRooms">
                    <h5
                      id="VIP"
                      className="text-white d-inline text-uppercase"
                      onClick={() => loadRoom("Sala VIP")}
                    >
                      SALA VIP
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                    <h5
                      className="text-white d-inline text-uppercase"
                      onClick={() => loadRoom("Editorial")}
                    >
                      EDITORIAL
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                    <h5
                      className="text-white d-inline text-uppercase"
                      value={"Estudio de Podcast"}
                      onClick={() => loadRoom("Estudio de Podcast")}
                    >
                      Estudio de Podcast
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                    <h5
                      className="text-white d-inline text-uppercase"
                      value={"Hall"}
                      onClick={() => loadRoom("Hall")}
                    >
                      Hall
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                    <h5
                      className="text-white d-inline text-uppercase"
                      value={"Estudio"}
                      onClick={() => loadRoom("Estudio")}
                    >
                      Estudio
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                    <h5
                      className="text-white d-inline text-uppercase"
                      value={"Presidencia"}
                      onClick={() => loadRoom("Presidencia")}
                    >
                      Presidencia
                      <img
                        className="plus me-0 ms-2"
                        src="/images/plus.svg"
                        alt="sala"
                      />
                    </h5>
                  </div>
                </div>
              </div>
              {/* incio seleccion de salas*/}
              {sala === "Sala VIP" ? (
                <div className="bgVip"></div>
              ) : sala === "Editorial" ? (
                <div className="bgEditorial"></div>
              ) : sala === "Estudio de Podcast" ? (
                <div className="bgCabina"></div>
              ) : sala === "Hall" ? (
                <div className="bgHall"></div>
              ) : sala === "Estudio" ? (
                <div className="bgVideo"></div>
              ) : sala === "Presidencia" ? (
                <div className="bgPresi"></div>
              ) : (
                <div className="bgVip"></div>
              )}
              {/* fin seleccion de salas*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Reservation;
